/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

//require('./bootstrap');
//import sass files
import '../sass/app.scss';

//import { createApp } from 'vue';
import { createApp } from "vue/dist/vue.esm-bundler";

//used for forms system
import mitt from 'mitt';

//iport Vue components
import BgOfficeSelect from './components/FormSelect/BgOfficeSelect.vue';
import BgPaketomatSelect from './components/FormSelect/BgPaketomatSelect.vue';
import BgCitySelect from './components/FormSelect/BgCitySelect.vue';
import BgQuarterSelect from './components/FormSelect/BgQuarterSelect.vue';
import GrCitySelect from './components/FormSelect/GrCitySelect.vue';
import MkCitySelect from './components/FormSelect/MkCitySelect.vue';
import RoCountyLocalitySelect from './components/FormSelect/RoCountyLocalitySelect.vue';
import SamedayParcelMachineSelect from './components/FormSelect/RO/SamedayParcelMachineSelect.vue';
import ProvinceSelect from './components/FormSelect/ProvinceSelect.vue';
import FrZipCodeSelect from './components/FormSelect/FrZipCodeSelect.vue';
import PlInpostOfficeSelect from './components/FormSelect/PL/PlInpostOfficeSelect.vue';
import PlInpostParcelSelect from './components/FormSelect/PL/PlInpostParcelSelect.vue';
import MagyarPickupPointSelect from './components/FormSelect/HU/MagyarPickupPointSelect.vue';
import DPDBalticPickupPointSelect from './components/FormSelect/Baltic/DPDBalticPickupPointSelect.vue';
import DeHermesPickupPointSelect from './components/FormSelect/DE/DeHermesPickupPointSelect.vue';
import SiPostaSlovenijeOfficeSelect from './components/FormSelect/SI/SiPostaSlovenijeOfficeSelect.vue';
import SiPostaSlovenijePickupPointSelect from './components/FormSelect/SI/SiPostaSlovenijePickupPointSelect.vue';
import ChronopostPickupPointSelect
    from "./components/FormSelect/PT/ChronopostPickupPointSelect.vue";
import BalikPostOfficeSelect from './components/FormSelect/Balik/BalikPostOfficeSelect.vue';
import BalikParcelSelect from './components/FormSelect/Balik/BalikParcelSelect.vue';
import SkPostaPickupPointSelect
    from "./components/FormSelect/SK/SkPostaPickupPointSelect.vue";
import SkPostaPostOfficeSelect
    from "./components/FormSelect/SK/SkPostaPostOfficeSelect.vue";
import NlDhlPickupPointSelect
    from "./components/FormSelect/NL/NlDhlPickupPointSelect.vue";
import DeDhlPickupPointSelect
    from "./components/FormSelect/DE/DeDhlPickupPointSelect.vue";
import RoFanPickupPointSelect
    from "./components/FormSelect/RO/RoFanPickupPointSelect.vue";
import BgBoxnowParcelSelect
    from "./components/FormSelect/BG/BgBoxnowParcelSelect.vue";
import GrBoxnowParcelSelect
    from "./components/FormSelect/GR/GrBoxnowParcelSelect.vue";

// TODO: Unify all boxnow selects, so we avoid code duplication
import BoxnowParcelSelect
    from "./components/FormSelect/Boxnow/BoxnowParcelSelect.vue";
//handle php translations
import { i18nVue } from 'laravel-vue-i18n';

// vue-select component, for "searching select" input
// https://github.com/sagalbot/vue-select
import vSelect from "vue-select";

// alpine JS for easy DOM manipulation
import Alpine from 'alpinejs';

/**
 * Form related functions
 *
 * @type {{submitForm: Window.Form.submitForm}}
 */
window.Form = {
    /**
     * Prevent duplicate form submission
     * @param {HTMLElement} element
     */
    submitForm: function (element) {
        if (element.getAttribute('data-submitted')) {
            event.preventDefault();
            return;
        }

        element.setAttribute('data-submitted', "1");

        let buttons = element.querySelectorAll('button');
        buttons.forEach((button) => button.classList.add('is-loading'));
    }
};
window.Alpine = Alpine;

const emitter = mitt();



const app = createApp({});

app.config.globalProperties.emitter = emitter;
//app.use(i18n);
app.use(i18nVue, {
    resolve: async lang => {
        const langs = import.meta.glob('../../lang/*.json');
        return await langs[`../../lang/${lang}.json`]();
    }
});

// don't remove trim
// ref: https://stackoverflow.com/questions/39529870/ifprocess-env-node-env-production-always-false
if (process.env.NODE_ENV.trim() === "production") {
    app.config.devtools = false;
    app.config.debug = false;
    app.config.silent = true;
}


// BG office picker
app.component('BgOfficeSelect', BgOfficeSelect);
// BG paketomat picker
app.component('BgPaketomatSelect', BgPaketomatSelect);
// BG city picker
app.component('BgCitySelect', BgCitySelect);
// BG quarter/neighborhood picker
app.component('BgQuarterSelect', BgQuarterSelect);
// GR city select
app.component('GrCitySelect', GrCitySelect);
// MK city select
app.component('MkCitySelect', MkCitySelect);
// RO county and locality select
app.component('RoCountyCitySelect', RoCountyLocalitySelect);
// RO parcel machine select
app.component('RoSamedayParcelMachineSelect', SamedayParcelMachineSelect);
// IT province
app.component('ProvinceSelect', ProvinceSelect);
// FR zip code
app.component('FrZipCode', FrZipCodeSelect);
//PL Office Select
app.component('PlInpostOfficeSelect', PlInpostOfficeSelect);
//PL Parcel Select
app.component('PlInpostParcelSelect', PlInpostParcelSelect);
//HU Pickup Point select
app.component('MagyarPickupPointSelect', MagyarPickupPointSelect);
//DPD Baltic Pickup Point Select
app.component('DPDBalticPickupPointSelect', DPDBalticPickupPointSelect);
//DE Hermes pickup point select
app.component('DeHermesPickupPointSelect', DeHermesPickupPointSelect);

//NL DHL pickup point select
app.component('NlDhlPickupPointSelect', NlDhlPickupPointSelect);

//DE DHL pickup point select
app.component('DeDhlPickupPointSelect', DeDhlPickupPointSelect);

//SI Post office select
app.component('SiPostaSlovenijeOfficeSelect', SiPostaSlovenijeOfficeSelect);
//SI Pickup point select
app.component('SiPostaSlovenijePickupPointSelect', SiPostaSlovenijePickupPointSelect);

// SK pickup point
app.component('SkPostaPickupPointSelect', SkPostaPickupPointSelect);
app.component('SkPostaPostOfficeSelect', SkPostaPostOfficeSelect);


// PT chronopost pickup point
app.component('ChronopostPickupPointSelect', ChronopostPickupPointSelect);

app.component('RoFanPickupPointSelect', RoFanPickupPointSelect);

app.component('BgBoxnowParcelSelect', BgBoxnowParcelSelect);

app.component('GrBoxnowParcelSelect', GrBoxnowParcelSelect);

// TODO: Unify all boxnow selects, so we avoid code duplication
app.component('BoxnowParcelSelect', BoxnowParcelSelect);

//Balik Post Office select
app.component('BalikPostOfficeSelect', BalikPostOfficeSelect);
//Balik Parcel select
app.component('BalikParcelSelect', BalikParcelSelect);
// v-select component
app.component("VSelect", vSelect);

app.mount('#app');

Alpine.start();
